import React, { useRef, useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';

function App() {

	const [pageBeingSeen, set_pageBeingSeen] = useState("listCheese");	// listCheese listDate singleCheese singleCheeseEdit alert confirmDelete
	const [localCopyAllcheese, set_localCopyAllcheese] = useState([]);
	const [localCopyAllDates, set_localCopyAllDates] = useState([]);
	const [searchValueCheese, set_searchValueCheese] = useState(`search`);
	const [searchValueDate, set_searchValueDate] = useState(`search`);
	const [singleCheese, set_singleCheese] = useState({});
	const [dateIdVale, set_dateIdVale] = useState(`CHEESE ID`);
	const [alertMessage, set_alertMessage] = useState(``);
	const [confirmDelete, set_confirmDelete] = useState({});

	useEffect(() => {
		// Run! Like go get some data from an API.

		fetch('/getallcheese', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then(response => response.json())
			.then(responseFormatJson => {
				// console.log(`done`);
				// console.log(responseFormatJson);
				set_localCopyAllcheese(responseFormatJson.allCheese)
			})
	}, []);

	let helperFunction = (edit)=>{
		if(edit == false){
			return("EDIT");
		}else{
			return("SAVE");
		}
	}

	if(pageBeingSeen == "listCheese"){
		return(
			<div
				style={{
					display : "grid",
					"gridTemplateRows" : "1fr 9fr",
					height: "100vh",
					overflow: "hidden",
				}}
			>

				<input
					style={{
						textAlign : "center",
						fontSize : "2em",
					}}
					value={searchValueCheese}
					onBlur={()=>{
						if(searchValueCheese == ""){
							set_searchValueCheese("search")
						}
					}}
					onClick={()=>{
						set_searchValueCheese("");
					}}
					onChange={(event)=>{
						set_searchValueCheese(event.target.value)
					}}
				/>
				<div
					style={{
						overflow: "scroll",
						display : "grid",
						gridTemplateRows : "1fr",
						// gridTemplateColumns : "auto",
						rowGap : "10px",
					}}
				>
					<br
						style={{
							height : "10px",
						}}
					/>
					{localCopyAllcheese.map((i, index)=>{
						if(searchValueCheese === `` | searchValueCheese === `search`){
							return(
								<div
									key={index}
									style={{
										width : "90vw",
										border: "solid #c82e29 5px",
										backgroundColor: "#fafafa",
										fontSize: "2em",
										marginLeft : "5vw",
										// height : "20vh",
									}}
									onClick={()=>{
										set_singleCheese(i);
										set_pageBeingSeen("singleCheese");
										set_searchValueCheese("search")
									}}
								>
									-{String(i.Producer)} <br/>
									-{String(i.ItemName)} <br/>
									{String(i.PLU)}
								</div>
							)
						}else{
							if(i.Producer.toLowerCase().includes(searchValueCheese.toLowerCase()) | i.ItemName.toLowerCase().includes(searchValueCheese.toLowerCase()) | i.PLU.includes(searchValueCheese) ){
								return(
									<div
										key={index}
										style={{
											width : "90vw",
											border: "solid #c82e29 5px",
											backgroundColor: "#fafafa",
											fontSize: "2em",
											marginLeft : "5vw",
											// height : "20vh",
										}}
										onClick={()=>{
											set_singleCheese(i);
											set_pageBeingSeen("singleCheese");
										}}
									>
										-{String(i.Producer)} <br/>
										-{String(i.ItemName)} <br/>
										{String(i.PLU)}
									</div>
								)
							}else{
								return(null)
							}
						}
					})}

					<div
						style={{
							width : "90vw",
							border: "solid #c82e29 5px",
							backgroundColor: "#fafafa",
							fontSize: "2em",
							marginLeft : "5vw",
							textAlign : "center",
							// height : "20vh",
						}}
						onClick={()=>{
							set_pageBeingSeen("singleCheeseEdit");
							set_singleCheese({
								Producer : "",
								ItemName : "",
								PLU : "",
								CutStyle : "",
								Whole : "",
								Half : "",
								QtrCut : "",
								SoldAs : "",
								family : "",
							})
							set_searchValueCheese("search")
						}}
					>
						<p>add new cheese</p>
					</div>

					<div
						style={{
							width : "90vw",
							border: "solid #c82e29 5px",
							backgroundColor: "#fafafa",
							fontSize: "2em",
							marginLeft : "5vw",
							textAlign : "center",
							// height : "20vh",
						}}
						onClick={()=>{
							fetch('/dates', {
								method: 'POST',
								headers: {
									'Content-Type': 'application/json',
								},
							})
								.then(response => response.json())
								.then(responseFormatJson => {
									set_localCopyAllDates(responseFormatJson.cheeseByDate);
									set_searchValueCheese("search");
									set_pageBeingSeen("listDate");
								})
						}}
					>
						<p>date veiw</p>
					</div>

					<br/>

				</div>

			</div>
		)
	}
	if(pageBeingSeen == "singleCheese"){
		return(
			<div
				style={{
					overflow : "scroll",
				}}
			>
				<table
					onClick={()=>{
						set_pageBeingSeen("listCheese");
						set_singleCheese({});
					}}
				>
					<tbody>
						<tr
							style={{
								display : "grid",
								gridTemplateColumns : "9fr 11fr",
							}}
						>
							<td>Producer</td>
							<td>{String(singleCheese.Producer)}</td>
							{/*<td>
								<textarea
									style={{
										fontSize : "1em",
										width : "40vw",
										rows : "1",
									}}
									value={String(singleCheese.Producer)}
									onChange={(event)=>{
										let temp_singleCheese = JSON.parse(JSON.stringify({singleCheese : singleCheese})).singleCheese
										temp_singleCheese.Producer = event.target.value;
										set_singleCheese(temp_singleCheese);
									}}
								/>
							</td>*/}
						</tr>
						<tr
							style={{
								display : "grid",
								gridTemplateColumns : "9fr 11fr",
							}}
						>
							<td>Item Name</td>
							<td>{String(singleCheese.ItemName)}</td>
							{/*<td>
								<textarea
									style={{
										fontSize : "1em",
										width : "40vw",
										rows : "1",
									}}
									value={String(singleCheese.ItemName)}
									onChange={(event)=>{
										let temp_singleCheese = JSON.parse(JSON.stringify({singleCheese : singleCheese})).singleCheese
										temp_singleCheese.ItemName = event.target.value;
										set_singleCheese(temp_singleCheese);
									}}
								/>
							</td>*/}
						</tr>
						<tr
							style={{
								display : "grid",
								gridTemplateColumns : "9fr 11fr",
							}}
						>
							<td>PLU</td>
							<td>{String(singleCheese.PLU)}</td>
							{/*<td>
								<textarea
									style={{
										fontSize : "1em",
										width : "40vw",
										rows : "1",
									}}
									value={String(singleCheese.PLU)}
									onChange={(event)=>{
										let temp_singleCheese = JSON.parse(JSON.stringify({singleCheese : singleCheese})).singleCheese
										temp_singleCheese.PLU = event.target.value;
										set_singleCheese(temp_singleCheese);
									}}
								/>
							</td>*/}
						</tr>
						<tr
							style={{
								display : "grid",
								gridTemplateColumns : "9fr 11fr",
							}}
						>
							<td>Cut Style</td>
							<td>{String(singleCheese.CutStyle)}</td>
							{/*<td>
								<textarea
									style={{
										fontSize : "1em",
										width : "40vw",
										rows : "1",
									}}
									value={String(singleCheese.CutStyle)}
									onChange={(event)=>{
										let temp_singleCheese = JSON.parse(JSON.stringify({singleCheese : singleCheese})).singleCheese
										temp_singleCheese.CutStyle = event.target.value;
										set_singleCheese(temp_singleCheese);
									}}
								/>
							</td>*/}
						</tr>
						<tr
							style={{
								display : "grid",
								gridTemplateColumns : "9fr 11fr",
							}}
						>
							<td>Whole</td>
							<td>{String(singleCheese.Whole)}</td>
							{/*<td>
								<textarea
									style={{
										fontSize : "1em",
										width : "40vw",
										rows : "1",
									}}
									value={String(singleCheese.Whole)}
									onChange={(event)=>{
										let temp_singleCheese = JSON.parse(JSON.stringify({singleCheese : singleCheese})).singleCheese
										temp_singleCheese.Whole = event.target.value;
										set_singleCheese(temp_singleCheese);
									}}
								/>
							</td>*/}
						</tr>
						<tr
							style={{
								display : "grid",
								gridTemplateColumns : "9fr 11fr",
							}}
						>
							<td>Half</td>
							<td>{String(singleCheese.Half)}</td>
							{/*<td>
								<textarea
									style={{
										fontSize : "1em",
										width : "40vw",
										rows : "1",
									}}
									value={String(singleCheese.Half)}
									onChange={(event)=>{
										let temp_singleCheese = JSON.parse(JSON.stringify({singleCheese : singleCheese})).singleCheese
										temp_singleCheese.Half = event.target.value;
										set_singleCheese(temp_singleCheese);
									}}
								/>
							</td>*/}
						</tr>
						<tr
							style={{
								display : "grid",
								gridTemplateColumns : "9fr 11fr",
							}}
						>
							<td>Qtr / Cut</td>
							<td>{String(singleCheese.QtrCut)}</td>
							{/*<td>
								<textarea
									style={{
										fontSize : "1em",
										width : "40vw",
										rows : "1",
									}}
									value={String(singleCheese.QtrCut)}
									onChange={(event)=>{
										let temp_singleCheese = JSON.parse(JSON.stringify({singleCheese : singleCheese})).singleCheese
										temp_singleCheese.QtrCut = event.target.value;
										set_singleCheese(temp_singleCheese);
									}}
								/>
							</td>*/}
						</tr>
						<tr
							style={{
								display : "grid",
								gridTemplateColumns : "9fr 11fr",
							}}
						>
							<td>Sold As</td>
							<td>{String(singleCheese.SoldAs)}</td>
							{/*<td>
								<textarea
									style={{
										fontSize : "1em",
										width : "40vw",
										rows : "1",
									}}
									value={String(singleCheese.SoldAs)}
									onChange={(event)=>{
										let temp_singleCheese = JSON.parse(JSON.stringify({singleCheese : singleCheese})).singleCheese
										temp_singleCheese.SoldAs = event.target.value;
										set_singleCheese(temp_singleCheese);
									}}
								/>
							</td>*/}
						</tr>
						<tr
							style={{
								display : "grid",
								gridTemplateColumns : "9fr 11fr",
							}}
						>
							<td>family</td>
							<td>{String(singleCheese.family)}</td>
							{/*<td>
								<textarea
									style={{
										fontSize : "1em",
										width : "40vw",
										rows : "1",
									}}
									value={String(singleCheese.family)}
									onChange={(event)=>{
										let temp_singleCheese = JSON.parse(JSON.stringify({singleCheese : singleCheese})).singleCheese
										temp_singleCheese.family = event.target.value;
										set_singleCheese(temp_singleCheese);
									}}
								/>
							</td>*/}
						</tr>
					</tbody>
				</table>

				<br/>

				<button
					style={{
						width : "100vw",
						height : "10vh",
						fontSize : "1.7em",
					}}
					onClick={()=>{
						set_pageBeingSeen("singleCheeseEdit");
					}}
				>
					edit
				</button><br/>

				<br/>

				<input
					id="expirationDateinput"
					type="date"
					style={{
						width : "90vw",
						marginLeft : "5vw",
						fontSize : "2em",
						textAlign : "center",
					}}
				/><br/>
				
				<br/>

				<input
					style={{
						width : "90vw",
						marginLeft : "5vw",
						textAlign : "center",
						fontSize : "2em",
					}}
					value={dateIdVale}
					onBlur={()=>{
						if(dateIdVale == ""){
							set_dateIdVale("CHEESE ID")
						}
					}}
					onClick={()=>{
						set_dateIdVale("");
					}}
					onChange={(event)=>{
						set_dateIdVale(event.target.value)
					}}
				/><br/>

				<br/>

				<button
					style={{
						width : "100vw",
						height : "10vh",
						fontSize : "1.7em",
					}}
					onClick={()=>{

						let epoch_today = Date.parse(new Date());
						// console.log(`epoch_today : ${epoch_today}`);

						let epoch_outdated = Date.parse(document.getElementById(`expirationDateinput`).value);
						// console.log(`epoch_outdated : ${epoch_outdated}`);

						let epoch_actionRequired = (epoch_outdated - (singleCheese.Half * 86400000));
						// console.log(`epoch_actionRequired : ${epoch_actionRequired}`);

						fetch('/saveWhole', {
								method: 'POST',
								headers: {
									'Content-Type': 'application/json',
								},
								body: JSON.stringify({
									"ItemName" : singleCheese.ItemName,
									"cheeseObj" : singleCheese,
									"epoch_today" : epoch_today,
									"epoch_actionRequired" : epoch_actionRequired,
									"epoch_outdated" : epoch_outdated,
								}),
							})
								.then(response => response.json())
								.then(responseFormatJson => {
									// console.log(responseFormatJson)
									if(responseFormatJson.error == null){
										set_alertMessage(responseFormatJson.smallId)
										set_searchValueCheese(`search`);
										set_pageBeingSeen("alert");
									}else{
										set_alertMessage(`enter a date… if that dose not work talk to Sassan`);
										set_searchValueCheese(`search`);
										set_pageBeingSeen("alert");
									}
								})
					}}
				>
					CHECK IN WHEEL
				</button><br/>

				<br/>

				<button
					style={{
						width : "100vw",
						height : "10vh",
						fontSize : "1.7em",
					}}
					onClick={()=>{
						let epoch_today = Date.parse(new Date());
						// console.log(`epoch_today : ${epoch_today}`);

						let epoch_outdated = Date.parse(document.getElementById(`expirationDateinput`).value);
						// console.log(`epoch_outdated : ${epoch_outdated}`);

						let epoch_actionRequired = (epoch_outdated - (singleCheese.QtrCut * 86400000));
						// console.log(`epoch_actionRequired : ${epoch_actionRequired}`);
						// console.log(`epoch_actionRequired == "NaN" : ${epoch_actionRequired == "NaN"}`);

						let temp_dateIdVale = "";
						if(!(dateIdVale == "CHEESE ID")){
							temp_dateIdVale = JSON.parse(JSON.stringify({dateIdVale : dateIdVale})).dateIdVale;
						}
						// console.log(`temp_dateIdVale : ${temp_dateIdVale}`);

						fetch('/saveHalf', {
								method: 'POST',
								headers: {
									'Content-Type': 'application/json',
								},
								body: JSON.stringify({
									"ItemName" : singleCheese.ItemName,
									"smallId" : temp_dateIdVale,
									"cheeseObj" : singleCheese,
									"epoch_today" : epoch_today,
									"epoch_actionRequired" : epoch_actionRequired,
									"epoch_outdated" : epoch_outdated,
								}),
							})
								.then(response => response.json())
								.then(responseFormatJson => {
									// console.log(responseFormatJson)
									if(responseFormatJson.error == null){
										set_alertMessage(responseFormatJson.smallId)
										set_searchValueCheese(`search`);
										set_pageBeingSeen("alert");
										set_singleCheese({})
									}else{
										set_alertMessage(`enter a date… if that dose not work talk to Sassan`);
										set_searchValueCheese(`search`);
										set_pageBeingSeen("alert");
										set_singleCheese({})
									}
								})
					}}
				>
					CHECK IN HALF
				</button><br/>

				<br/>

				<button
					style={{
						width : "100vw",
						height : "10vh",
						fontSize : "1.7em",
					}}
					onClick={()=>{
						let epoch_today = Date.parse(new Date());
						// console.log(`epoch_today : ${epoch_today}`);

						let epoch_outdated = Date.parse(document.getElementById(`expirationDateinput`).value);
						// console.log(`epoch_outdated : ${epoch_outdated}`);

						let epoch_actionRequired = (epoch_outdated - (2 * 86400000));
						// console.log(`epoch_actionRequired : ${epoch_actionRequired}`);
						// console.log(`epoch_actionRequired == "NaN" : ${epoch_actionRequired == "NaN"}`);

						let temp_dateIdVale = "";
						if(!(dateIdVale == "CHEESE ID")){
							temp_dateIdVale = JSON.parse(JSON.stringify({dateIdVale : dateIdVale})).dateIdVale;
						}
						// console.log(`temp_dateIdVale : ${temp_dateIdVale}`);

						fetch('/saveQtrCut', {
								method: 'POST',
								headers: {
									'Content-Type': 'application/json',
								},
								body: JSON.stringify({
									"ItemName" : singleCheese.ItemName,
									"smallId" : temp_dateIdVale,
									"cheeseObj" : singleCheese,
									"epoch_today" : epoch_today,
									"epoch_actionRequired" : epoch_actionRequired,
									"epoch_outdated" : epoch_outdated,
								}),
							})
								.then(response => response.json())
								.then(responseFormatJson => {
									// console.log(responseFormatJson)
									if(responseFormatJson.error == null){
										set_alertMessage(responseFormatJson.smallId)
										set_searchValueCheese(`search`);
										set_pageBeingSeen("alert");
										set_singleCheese({})
									}else{
										set_alertMessage(`enter a date… if that dose not work talk to Sassan`);
										set_searchValueCheese(`search`);
										set_pageBeingSeen("alert");
										set_singleCheese({})
									}
								})
					}}
				>
					CHECK IN PICE
				</button><br/>

				<br/>
			</div>
		)
	}
	if(pageBeingSeen == "singleCheeseEdit"){
		return(
			<div
				style={{
					overflow : "scroll",
				}}
			>
				<table>
					<tbody>
						<tr
							style={{
								display : "grid",
								gridTemplateColumns : "9fr 11fr",
							}}
						>
							<td>Producer</td>
							<td>
								<textarea
									style={{
										fontSize : "1em",
										width : "40vw",
										rows : "1",
									}}
									value={String(singleCheese.Producer)}
									onChange={(event)=>{
										let temp_singleCheese = JSON.parse(JSON.stringify({singleCheese : singleCheese})).singleCheese
										temp_singleCheese.Producer = event.target.value;
										set_singleCheese(temp_singleCheese);
									}}
								/>
							</td>
						</tr>
						<tr
							style={{
								display : "grid",
								gridTemplateColumns : "9fr 11fr",
							}}
						>
							<td>Item Name</td>
							<td>
								<textarea
									style={{
										fontSize : "1em",
										width : "40vw",
										rows : "1",
									}}
									value={String(singleCheese.ItemName)}
									onChange={(event)=>{
										let temp_singleCheese = JSON.parse(JSON.stringify({singleCheese : singleCheese})).singleCheese
										temp_singleCheese.ItemName = event.target.value;
										set_singleCheese(temp_singleCheese);
									}}
								/>
							</td>
						</tr>
						<tr
							style={{
								display : "grid",
								gridTemplateColumns : "9fr 11fr",
							}}
						>
							<td>PLU</td>
							<td>
								<textarea
									style={{
										fontSize : "1em",
										width : "40vw",
										rows : "1",
									}}
									value={String(singleCheese.PLU)}
									onChange={(event)=>{
										let temp_singleCheese = JSON.parse(JSON.stringify({singleCheese : singleCheese})).singleCheese
										temp_singleCheese.PLU = event.target.value;
										set_singleCheese(temp_singleCheese);
									}}
								/>
							</td>
						</tr>
						<tr
							style={{
								display : "grid",
								gridTemplateColumns : "9fr 11fr",
							}}
						>
							<td>Cut Style</td>
							<td>
								<textarea
									style={{
										fontSize : "1em",
										width : "40vw",
										rows : "1",
									}}
									value={String(singleCheese.CutStyle)}
									onChange={(event)=>{
										let temp_singleCheese = JSON.parse(JSON.stringify({singleCheese : singleCheese})).singleCheese
										temp_singleCheese.CutStyle = event.target.value;
										set_singleCheese(temp_singleCheese);
									}}
								/>
							</td>
						</tr>
						<tr
							style={{
								display : "grid",
								gridTemplateColumns : "9fr 11fr",
							}}
						>
							<td>Whole</td>
							<td>
								<textarea
									style={{
										fontSize : "1em",
										width : "40vw",
										rows : "1",
									}}
									value={String(singleCheese.Whole)}
									onChange={(event)=>{
										let temp_singleCheese = JSON.parse(JSON.stringify({singleCheese : singleCheese})).singleCheese
										temp_singleCheese.Whole = event.target.value;
										set_singleCheese(temp_singleCheese);
									}}
								/>
							</td>
						</tr>
						<tr
							style={{
								display : "grid",
								gridTemplateColumns : "9fr 11fr",
							}}
						>
							<td>Half</td>
							<td>
								<textarea
									style={{
										fontSize : "1em",
										width : "40vw",
										rows : "1",
									}}
									value={String(singleCheese.Half)}
									onChange={(event)=>{
										let temp_singleCheese = JSON.parse(JSON.stringify({singleCheese : singleCheese})).singleCheese
										temp_singleCheese.Half = event.target.value;
										set_singleCheese(temp_singleCheese);
									}}
								/>
							</td>
						</tr>
						<tr
							style={{
								display : "grid",
								gridTemplateColumns : "9fr 11fr",
							}}
						>
							<td>Qtr / Cut</td>
							<td>
								<textarea
									style={{
										fontSize : "1em",
										width : "40vw",
										rows : "1",
									}}
									value={String(singleCheese.QtrCut)}
									onChange={(event)=>{
										let temp_singleCheese = JSON.parse(JSON.stringify({singleCheese : singleCheese})).singleCheese
										temp_singleCheese.QtrCut = event.target.value;
										set_singleCheese(temp_singleCheese);
									}}
								/>
							</td>
						</tr>
						<tr
							style={{
								display : "grid",
								gridTemplateColumns : "9fr 11fr",
							}}
						>
							<td>Sold As</td>
							<td>
								<textarea
									style={{
										fontSize : "1em",
										width : "40vw",
										rows : "1",
									}}
									value={String(singleCheese.SoldAs)}
									onChange={(event)=>{
										let temp_singleCheese = JSON.parse(JSON.stringify({singleCheese : singleCheese})).singleCheese
										temp_singleCheese.SoldAs = event.target.value;
										set_singleCheese(temp_singleCheese);
									}}
								/>
							</td>
						</tr>
						<tr
							style={{
								display : "grid",
								gridTemplateColumns : "9fr 11fr",
							}}
						>
							<td>family</td>
							<td>
								<textarea
									style={{
										fontSize : "1em",
										width : "40vw",
										rows : "1",
									}}
									value={String(singleCheese.family)}
									onChange={(event)=>{
										let temp_singleCheese = JSON.parse(JSON.stringify({singleCheese : singleCheese})).singleCheese
										temp_singleCheese.family = event.target.value;
										set_singleCheese(temp_singleCheese);
									}}
								/>
							</td>
						</tr>
					</tbody>
				</table>

				<br/>

				<button
					style={{
						width : "100vw",
						height : "10vh",
						fontSize : "1.7em",
					}}
					onClick={()=>{
						fetch('/updateCheese', {
							method: 'POST',
							headers: {
								'Content-Type': 'application/json',
							},
							body: JSON.stringify({
								Producer : singleCheese.Producer,
								ItemName : singleCheese.ItemName,
								PLU : singleCheese.PLU,
								CutStyle : singleCheese.CutStyle,
								Whole : singleCheese.Whole,
								Half : singleCheese.Half,
								QtrCut : singleCheese.QtrCut,
								SoldAs : singleCheese.SoldAs,
								family : singleCheese.family,
							}),
						})
							.then(response => response.json())
							.then(responseFormatJson => {
								set_singleCheese(responseFormatJson.thisCheese);
								set_localCopyAllcheese(responseFormatJson.allCheese)
								set_pageBeingSeen(`singleCheese`);
							})
					}}
				>
					save
				</button><br/>

				<br/>

				<button
					style={{
						width : "100vw",
						height : "10vh",
						fontSize : "1.7em",
					}}
					onClick={()=>{
						if(singleCheese.Producer == "" && singleCheese.ItemName == "" && singleCheese.PLU == "" && singleCheese.CutStyle == "" && singleCheese.Whole == "" && singleCheese.Half == "" && singleCheese.QtrCut == "" && singleCheese.SoldAs == "" && singleCheese.family == ""){
							set_pageBeingSeen(`listCheese`);
						}else{
							set_pageBeingSeen(`singleCheese`);
						}
					}}
				>
					cancel
				</button>
			</div>
		)
	}
	if(pageBeingSeen == "alert"){
		return(
			<div
				style={{
					fontSize : "3em",
					textAlign : "center",
					width : "100vw",
					height : "100vh",
				}}
				onClick={()=>{
					set_pageBeingSeen("listCheese");
				}}
			>
				{String(alertMessage)}
				{/*<textarea
					style={{
						width : "80vw",
						height : "80vh",
					}}
					value={alertMessage}
				/>*/}
			</div>
		)
	}
	if(pageBeingSeen == "listDate"){
		return(
			<div
				style={{
					display : "grid",
					"gridTemplateRows" : "1fr 9fr",
					height: "100vh",
					overflow: "hidden",
				}}
			>

				<input
					style={{
						textAlign : "center",
						fontSize : "2em",
					}}
					value={searchValueDate}
					onBlur={()=>{
						if(searchValueDate == ""){
							set_searchValueDate("search")
						}
					}}
					onClick={()=>{
						set_searchValueDate("");
					}}
					onChange={(event)=>{
						set_searchValueDate(event.target.value)
					}}
				/>
				<div
					style={{
						overflow: "scroll",
						display : "grid",
						gridTemplateRows : "1fr",
						// gridTemplateColumns : "auto",
						rowGap : "10px",
					}}
				>
					<br
						style={{
							height : "10px",
						}}
					/>
					{localCopyAllDates.map((i, index)=>{
						if(searchValueDate === `` | searchValueDate === `search`){
							return(
								<div
									key={index}
									style={{
										width : "90vw",
										border: "solid #c82e29 5px",
										backgroundColor: "#fafafa",
										fontSize: "2em",
										marginLeft : "5vw",
									}}
									onClick={()=>{
										set_confirmDelete(i);
										set_pageBeingSeen(`confirmDelete`);
									}}
								>
									-{i.cheeseObj.Producer} <br/>
									-{i.cheeseObj.ItemName} <br/>
									-{i.type} <br/>
									-short id : {i.smallId} <br/>
									<hr/>
									- action required : {String(new Date(i.epoch_actionRequired).toLocaleDateString('en-US'))} <br/>
									<hr/>
									- outdated : {String(new Date(i.epoch_outdated).toLocaleDateString('en-US'))} <br/>
								</div>
							)
						}else{
							if(i.cheeseObj.Producer.toLowerCase().includes(searchValueDate.toLowerCase()) | i.cheeseObj.ItemName.toLowerCase().includes(searchValueDate.toLowerCase()) | i.smallId.includes(searchValueDate.toUpperCase()) ){
								return(
									<div
										key={index}
										style={{
											width : "90vw",
											border: "solid #c82e29 5px",
											backgroundColor: "#fafafa",
											fontSize: "2em",
											marginLeft : "5vw",
										}}
										onClick={()=>{
											set_confirmDelete(i);
											set_pageBeingSeen(`confirmDelete`);
										}}
									>
										-{i.cheeseObj.Producer} <br/>
										-{i.cheeseObj.ItemName} <br/>
										-{i.type} <br/>
										-short id : {i.smallId} <br/>
										<hr/>
										- action required : {String(new Date(i.epoch_actionRequired).toLocaleDateString('en-US'))} <br/>
										<hr/>
										- outdated : {String(new Date(i.epoch_outdated).toLocaleDateString('en-US'))} <br/>
									</div>
								)
							}
						}

					})}

						<br/>

						<button
							style={{
								width : "100vw",
								height : "10vh",
								fontSize : "1.7em",
							}}
							onClick={()=>{
								set_searchValueDate("search");
								set_pageBeingSeen("listCheese");
							}}
						>
							check in cheese
						</button>

					<br/>

				</div>

			</div>
		)
	}
	if(pageBeingSeen == "confirmDelete"){
		return(
			<div>

				<br/>
				<br/>
				<br/>

				<button
					style={{
						width : "95vw",
						marginLeft : "2.5vw",
						height : "10vh",
						fontSize : "1.7em",
					}}
					onClick={()=>{
						fetch('/deleteDate', {
							method: 'POST',
							headers: {
								'Content-Type': 'application/json',
							},
							body: JSON.stringify({
								smallId : confirmDelete.smallId
							}),
						})
							.then(response => response.json())
							.then(responseFormatJson => {
								set_localCopyAllDates(responseFormatJson.cheeseByDate);
								set_confirmDelete({});
								set_pageBeingSeen("listDate");
							})
					}}
				>
					CONFIRM DELETE
				</button><br/>

				<br/>

				<div
					style={{
						width : "90vw",
						border: "solid #c82e29 5px",
						backgroundColor: "#fafafa",
						fontSize: "2em",
						marginLeft : "5vw",
					}}
				>
					-{confirmDelete.cheeseObj.Producer} <br/>
					-{confirmDelete.cheeseObj.ItemName} <br/>
					-{confirmDelete.type} <br/>
					-short id : {confirmDelete.smallId} <br/>
					<hr/>
					- action required : {String(new Date(confirmDelete.epoch_actionRequired).toLocaleDateString('en-US'))} <br/>
					<hr/>
					- outdated : {String(new Date(confirmDelete.epoch_outdated).toLocaleDateString('en-US'))} <br/>
				</div>

				<br/>

			</div>
		)
	}
}

export default App;
